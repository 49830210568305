@import "src/styles/settings";
@import "src/styles/mixins/_container";
@import "src/styles/_typography";
@import "src/components/logo/_logo.scss";
@import "src/components/button-create/_button-create.scss";


$header_height: 96px;

.header {
  height: $header_height;
  background-color: $c_white;
  body.dataset-test & {
    background-color: $c_yellow-20;
  }
  &__container {
    @include container(1200px, $gutter, $fs_base);
    padding-right: 0;
    display: flex;
    height: 100%;

    @media screen and (min-width: $mobile-width) {
      padding-right: 0;
    }
  }

  &__nav {
    display: flex;
    flex-grow: 1;
    align-items: center;
    text-align: center;
    line-height: $lh_header;
  }

  &__nav-items {
    display: none; // hide below beta-width

    @media screen and (min-width: $beta-width) {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__nav-item {
    display: block;
    margin: 0 1rem;
    height: 100%;
  }

  &__nav-link {
    position: relative;
    display: flex;
    align-items: center;
    color: $c_gunmetal;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 12px;
      background-color: transparent;
    }

    &:hover:after {
      background-color: mix($c_gunmetal, $c_white, 80%);
    }

    [data-active="true"] &:after {
      background-color: $c_gunmetal;
    }
  }


  &-main_logo {
    @include logo("light", "medium");
    margin-right: 2rem;
  }

  &-main-public_logo {
    @include logo("light", "medium");
    margin-right: 2rem;
  }
}