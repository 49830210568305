@import "src/styles/_typography.scss";
@import "src/styles/mixins/_container";

@mixin article-header() {
  &__content {
    @include container();
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex: 1;
    
    &__supertitle {
      min-height: 1.2rem; // ensures the element always has a height and acts as padding even though it's empty
      @extend .h6;
      font-weight: $fw_normal;
      font-size: $fs_medium;
      
    }
    &__title {
      @extend .h2;
      margin-bottom: 0.25em;
    }
    &__back {
      color: $c_coral;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      margin: 1rem 0 2rem 0;
      
    }
    &__subtitle {
      font-size: $fs_medium_small;
      font-weight: $fw_normal;
      color: $c_slate-grey;
      @media screen and (min-width: 535px) {
        font-size: $fs_medium;
      }
      @media screen and (min-width: $mobile-width) {
        font-size: 27px; // ?
      }
    }
  }
}

@mixin setup-article-header() {
  .article-header {
    @include article-header();
  }
}
@include setup-article-header();
