@import "src/styles/settings";

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  color: $c_white;
  background-color: $c_gunmetal;
  tr {
  }
  // header cell
  th {
    font-weight: $fw_bold;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: left;
    &:first-child {
      padding-left: 1em;
      text-align: left;
    }
    &:last-child {
      padding-right: 1em;
    }
  }
}

tbody {
  color: $c_charcoal-grey;
  text-align: left;
  tr {

  }
  // table cell
  td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: left;
    vertical-align: middle;
    &:first-child {
      padding-left: 1em;
      text-align: left;
    }
    &:last-child {
      padding-right: 1em;
    }
    svg {
      margin-left: auto; // right align.
    }
  }
}

@mixin table (
  $size: "medium",
  $striped: false
){

  thead {
    th {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
  tbody {
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: $c_white;
    }
    &:nth-child(even) {
      background-color: $striped;
    }
  }
}
