@import "src/styles/settings";
@import "src/styles/_typography";

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  height: 8rem;
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: $c-light-grey-blue;
    color: $c-white;
    &__image {
      width: 24px;
      height: 24px;
    }
    &__active {
      background: $c-slate-grey;
    }
    &__label {
      position: absolute;
      display: block;
      font-size: 16px;
      line-height: 1.25;
      color: $c-light-grey-blue;      
      bottom: -2.5rem;
      vertical-align: middle;
      width: 120px;
      height: 2rem;
      text-align: center;
      &__active {
        color: $c-slate-grey;
      }
      }
  }
  &__line {
    &:first-child {
      display: none;
    }
    flex:1;
    height: 3px;
    border-radius: 0;
    border: dashed 2px $c-light-grey-blue;
    &__active {
      border: solid 2px $c-slate-grey;
    }
  }
}
