@import "src/styles/elements/_table.scss";
@import "src/styles/mixins/_container";

.project {

  &__page {
    &__header {
      &--rated {
        margin-top: 1rem;
        background: $c_slate-grey;
        color: $c-white;
      }
      &_content_message {
        color: $c_banana-yellow;
      }
    }
    &__progress {
      background: $c-white;
      padding-top: 0rem;
      padding-bottom: 1rem;
    }
    &__nav {
      background: $c-white;
      padding-top: 1rem;
      color: $c-coral;
    }
    &__notes {
      background: $c-white;
      padding-top: 2rem;
      padding-bottom: 3rem;
    }
    &__rating {
      padding-top: 2rem;
      padding-bottom: 3rem;
      background: $c-pale-grey;
      .estimation {
        float: right;
      }
    }
    &__byggfakta {
      background: $c-pale-grey;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    
    &__backlink {
      margin-left: -30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &_arrow {
        display: inline-block;
        transform: rotate(180deg);
        stroke: $c_coral;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &_field {
      &--elevated {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem;
        background: $c-light-grey-blue;
        & > button {
          flex: 1;
        }
        & > button + button {
          margin-left: 2rem;
        }
      }
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
      label {
        font-size: $fs_small;
        color: $c-charcoal-grey;
      }
    }
  }
}