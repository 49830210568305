@import "src/styles/elements/_button";

.input-inline + .input-inline
{
  margin-left: 1rem;
}
.input-inline {
  position: relative;
  display: inline-flex;


  &__input {
    @include button-2-factory();
    text-align: left;
    border: solid 2px $c_black;
    padding: 1rem;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=inline] {
      -moz-appearance: textfield;
    }

  }
}
