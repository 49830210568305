@import "src/styles/settings";
@import "src/styles/_typography";

.page-grid {
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 26px;
  grid-template-columns: repeat(4, 1fr);

  &_1 {
    grid-column: span 1;    
  }
  &_2 {
    grid-column: span 2;    
  }
  &_3 {
    grid-column: span 3;    
  }
  &_4 {
    grid-column: span 4;    
  }
  
  &_1-1 {
    grid-column-start: 1; 
    grid-column-end: 2; 
  }
  &_2-2 {
    grid-column-start: 2; 
    grid-column-end: 3; 
  }
  &_3-3 {
    grid-column-start: 3; 
    grid-column-end: 4; 
  }
  &_4-4 {
    grid-column-start: 4; 
    grid-column-end: 5; 
  }
  &_1-4 {
    grid-column-start: 1; 
    grid-column-end: 5; 
  }
  &_1-2 {
    grid-column-start: 1; 
    grid-column-end: 3; 
  }
  &_1-3 {
    grid-column-start: 1; 
    grid-column-end: 4; 
  }
  &_3-4 {
    grid-column-start: 3; 
    grid-column-end: 5; 
  }
  &_2 {
    grid-column: span 2;    
  }

}

.page-grid5 {
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 26px;
  grid-template-columns: repeat(5, 1fr);

  &_1 {
    grid-column: span 1;    
  }
  &_2 {
    grid-column: span 2;    
  }
  &_3 {
    grid-column: span 3;    
  }

}

.page-grid6 {
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 26px;
  grid-template-columns: repeat(6, 1fr);

  &_1 {
    grid-column: span 1;    
  }
  &_2 {
    grid-column: span 2;    
  }
  &_3 {
    grid-column: span 3;    
  }

}

.page-grid7 {
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 26px;
  grid-template-columns: repeat(7, 1fr);

  &_1 {
    grid-column: span 1;    
  }
  &_2 {
    grid-column: span 2;    
  }
  &_3 {
    grid-column: span 3;    
  }
  &_4 {
    grid-column: span 4;    
  }
}
