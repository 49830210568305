* {
box-sizing: border-box;
}

html,
body {
  font-family: $ff_body;
  font-size: $fs_base;
  line-height: $lh_base;
  -webkit-font-smoothing: antialiased;
}


body {
  color: $c_body-text;
  background-color: $c_body;
  // we set min-width for Bedömningsverktyget in layout style component
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.container {
  @include container($layout-maxwidth, $gutter, $fs_base);
}

bf-navigation {
  --bf-wc-navigation-height: 82px;
}
