@import "src/styles/settings.scss";
@import "src/components/article-header/article-header";
@import "src/styles/_typography";
@import "src/styles/_page-article";

.sanity-page,
.sanity-page-volumes {
  &__header {
    @include article-header();
    &__content {
      &__title {
        @include header($fs_xlarge);
        margin-bottom: 0.5rem;
        
        @media screen and (min-width: 535px) {
          font-size: $fs_xxlarge;
        }
        
        @media screen and (min-width: $mobile-width) {
          font-size: $fs_xxxlarge;
        }
      }
    }
    
  }

  &__content {
    font-size: $fs_small;

    @media screen and (min-width: $mobile-width) {
      font-size: $fs_base;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  ul {
    margin-bottom: 1rem;
    list-style-type: disc;
    padding-left: 1rem;

    @media screen and (min-width: $mobile-width) {
      padding-left: 3rem;
    }
  }
  li {
    font-weight: $fw_semibold;
    font-style: italic;
  }
  a {
    color: $c_link;
    &:hover {
      color: $c-link--hover-blue;
    }
  }
  strong {
    font-weight: $fw_bold;
  }
  strong > em {
    font-weight: $fw_bold;
  }
  em {
    font-style: italic;
  }
  figure {
    img {
      width: 100%;
    }
  }
}
