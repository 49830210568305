
.modal {
  max-width: $modal-maxwidth;
  min-width: $modal-minwidth;

  // Native (and polyfill) dialog styles take care of most things
  &__header {
    &__container {
      padding: 2rem;
      padding-right: 4rem; // Avoid crashing into close button
      background-color: $c_white;

      &__title {
        margin: 0;
        padding: 0;
      }
    }
  }

  &__content-container {
    padding: 2rem;
    overflow: scroll;
  }

  &__close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 24px;
    height: 24px;
    stroke: $c_slate-grey;

    &:hover {
      stroke: $c_black;
    }
  }

}