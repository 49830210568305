@import "src/styles/settings";

.prop-table {
  &__header {
    height: 36px;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2;
    margin: 15px 0;
    color: $c_charcoal-grey;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 61px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 21px;
    line-height: 1.43;
    color: $c_charcoal-grey;

    &__label {
      flex: 1;
    }

    &__value {}

    &:nth-child(even) {
      background-color: $c_pale-grey;
    }

    &:nth-child(odd) {
      background-color: $c-white;
    }
  }
}