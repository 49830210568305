@import "src/styles/settings.scss";
@import "src/components/article-header/article-header";
@import "src/components/article/_article.scss";


.news-article {
  &__wrapper {
    background-color: $c_white;
  }
  &__header {
    @include article-header();

    &__content {
      &__title {
        @include header($fs_xlarge);
        margin-bottom: 0.5rem;
        hyphens: auto;
        
        @media screen and (min-width: 400px) {
          hyphens: none;
        }

        @media screen and (min-width: 535px) {
          font-size: $fs_xxlarge;
        }
        
        @media screen and (min-width: $mobile-width) {
          font-size: $fs_xxxlarge;
        }
      }

      &__subtitle {
        width: 100%;
        @media screen and (min-width: $mobile-width) {
          width: 80%; // Calculate some other way?
        }
      }
    }
  }
  &__track-date-bar {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    @media screen and (min-width: $mobile-width) {
      flex-direction: row;
    }
    // .trackbar {
    //   font-size: $fs_small;

    //   @media screen and (min-width: $mobile-width) {
    //     font-size: $fs_base;
    //   }

    //   &__list {
    //     flex-wrap: wrap;
    //   }
    // }
  }
  &__datestamp {
    font-size: $fs_small;
    color: $c_slate-grey;
    margin-bottom: 0.5rem;

    @media screen and (min-width: $mobile-width) {
      margin-bottom: 0;
      font-size: $fs_base;
    }
  }

  &__article-section {
    @include article();
  }

  &__content {
    font-size: $fs_small;
    margin-top: 1rem;

    @media screen and (min-width: $mobile-width) {
      font-size: $fs_base;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }
}
