// MAIN RESET
//==============================================================================================================

html,
body,
main,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
option,
a,
input,
button,
select,
textarea {
    margin:0;
    padding:0;
    border:0;
    border-radius: 0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    font-weight: normal;
    font-style: normal;
    background:transparent;
}

input,
button,
select {
    vertical-align: middle;
}

button,
select{
    cursor: pointer;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

input:focus{
    outline: 0;
}

input[type="submit"]::-moz-focus-inner {
    border: 0;
}

ol,
ul{
    list-style: none;
}

article, aside, footer, header, hgroup, main, nav, section {
    display: block;
}