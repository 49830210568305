@import "src/styles/settings";
@import "src/styles/elements/_button";

@mixin selector() {
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &[data-state-disabled='true']:hover {
    cursor: not-allowed;
  }

  ul,
  ol,
  dl {
    list-style: none;
  }

  &__button {
    @include button-2-factory($background-color-active: $c_white);
    padding: 1em 3em 1em 1.33em;
    justify-content: flex-start;

    &:after {
      content: "";
      min-height: $lh_header * 1em;
    }

  }

  &__icon {
    // TODO: make icon bigger
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    stroke: $c_charcoal-grey;
    transition: transform $time-quick;
    // DART SASS TODO
    // [data-state-open='true'] & {
    //   transform: rotate(180deg);
    //   margin-top: -4px;
    // }
  }

  // DART SASS TODO
  // &[data-state-open='true'] {
  //   &__icon {
  //     transform: rotate(180deg);
  //   }
  // }
  &__list,
  & ul[role="menu"] {
    $height-item: 50px;
    $amount-items: 7; // ???
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: $height-item * $amount-items;
    border: $border;
    border-top: none;
    background-color: $c_white;
    margin: -1px 0 0;
    // padding: 1rem;
    overflow: hidden;
    overflow-y: auto;
    z-index: 6;

    &[data-direction='horizontal'] {
      display: flex;
      top: 0;
      left: 100%;
      width: auto;
    }
  }

  &__item {
    display: block;
    font-size: $fs_base;
    padding: 1rem;
    white-space: nowrap;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $c_pale-grey;
    }

    &:active,
    &[data-selected='true'] {
      cursor: default;
      color: $c_link--active;
      background-color: $c_pale-grey;
    }
  }
}

.selector {
  @include selector();
}