@import "src/styles/settings";
@import "src/styles/elements/_button";


.select-input {
  position: relative;
  display: inline-block;
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__select {
      max-width: 100%;
      flex: 1;
      @include button-2;

      text-align: left;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      padding-right:  2rem;
      &__noborder {
        border-color: transparent !important;
        background-color: transparent !important;
        &:focus,
        &:hover {
          background-color: transparent !important;
        }
      }
      &__dashedborder {
        border-style: dashed;
      }
      &:focus,
      &:hover {
        background-color: $c-white;
      }

    }
    &__icon {
      pointer-events: none;
      display: block;
      color: $c-black;
      margin-left: -2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      & {
        fill: #2e3a40;
        stroke: #2e3a40;
        vertical-align: baseline;
      }
    }
  }
}
