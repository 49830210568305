@import "src/styles/settings";
@import "src/styles/mixins/_container";
@import "src/styles/_typography";


.side-nav {
  height: 100%;
  z-index: 1;
  margin-left: auto;
  &__list {
    height: 100%;
  }
  &__toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 96px;
    background-color: $c_accent;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $c_accent--hover;
    }
    &:active {
      background-color: $c_accent--active;
    }
  }
  &__toggle-icon {

  }
  &__menu {
    position: absolute;
    width: 100%;
    right: 0;
    text-align: right;
    overflow: hidden;
    max-height: 0;
    transition: max-height $time-expand ease;
    background-color: $c_gunmetal;
    [data-expanded="true"] & {
      max-height: 500px;
    }
  }
  &__menu-container {
    @include container(1440px, $gutter, $fs_base);
    padding: 2rem 2rem;
    color: $c-white;
  }
  &__link {
    display: block;
    margin-bottom: 2rem;
    a {
      color: $c_white;
     }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .logout {
    a {
      color: $c_yellow;
      font-size: $fs_medium;
    }
  }
}

.mobile-nav {
  @media screen and (min-width: $beta-width) {
    display: none;
  }
  .side-nav {
    &__menu {
      text-align: left;
      &-container {
        padding: 2rem 1rem;
      }
    }
    &-item {
      padding: 0.5rem 0;
    }
    &-link {
      font-size: $fs_medium_large;
      font-weight: $fw_bold;
      @media screen and (min-width: $alpha-width) {
        font-size: $fs_large;
      }
    }
  }
}
