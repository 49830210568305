@import "src/styles/elements/_table.scss";
@import "src/components/article-header/article-header";

.forecasts {
  &__page {
    background: $c-pale-grey;
    padding-bottom: 2rem;

    &__section {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}
