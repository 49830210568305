@import "src/styles/mixins/_container";

.page-article {
  @include container;
  padding-top: 0;
  padding-bottom: 1rem;

  @media screen and (min-width: $mobile-width) {
    padding-top: 1rem;
    padding-bottom: 4.5rem;
  }
}