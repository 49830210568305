@import "src/styles/settings";
@import "src/styles/elements/_button";

textarea {
  word-wrap: break-word ;
  text-wrap: unrestricted;
  &[type="text"],
  &[type="email"],
  &[type="date"],
  &[type="datetime-local"],
  &[type="month"],
  &[type="number"],
  &[type="password"],
  &[type="search"],
  &[type="tel"],
  &[type="url"],
  &[type="week"]
   {
    @include button-2-factory(
      $background-color-hover: $c_white,
      $background-color-focus: $c_white,
      $background-color-active: $c_white,
    );
    white-space: normal;
    text-align: left;
    padding: 1em 1.33em;
    box-shadow: none; // handling this
    // &:required:blank
    &:invalid:not([value=""]) {
      color: $c_error;
      border-color: $c_error;
      transition: border-color 1000ms ease-in 1000ms, color 1000ms ease-in 1000ms; // regular typing will disguise the error message
      & ~ .textarea-error {
        opacity: 1;
      }
    }
    &::placeholder {
      color: $c_cool-grey;
    }
  }
  // Propbably not too robust of a solution.
  & ~ .textarea-unit {
    position: absolute;
    transform: translate(-2.5em, 0.7em);
    z-index: 1;
    color: $c_light-grey-blue;
    font-size: $fs_medium;
  }
}

.textarea-error {
  position: absolute;
  color: $c_error;
  opacity: 0;
  transition: opacity $time-quick ease-out;
  &[data-error="true"] {
    opacity: 1;
  }
}
