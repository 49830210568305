@import "src/styles/elements/_button";
.input-number {
  position: relative;
  display: flex;
  &__dashedborder &__input,
  &__dashedborder &__textarea {
    border-style: dashed;
  }
  &__input {
    @include button-2-factory();
    text-align: left;
    width: 100%;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

    &:read-only {
      background: rgba($c_white, 0.5);    
      border-color: rgba($c_button, 0.25);    
    }
    
  }

  &__unit {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 1em;
    height: 100%;
    vertical-align: middle;
    color: $c_light-grey-blue;
    font-size: $fs_medium;
    opacity: 1;
    transition: opacity $time-quick;
    input:focus ~ &,
    [data-active="true"] & {
      opacity: 0;
    }
  }
}
