dialog {
  background-color: $c_body;
  padding: 0;
  border: none;
  top:10%;
  max-height: 85%;
  position: fixed;
  display: flex;
  flex-direction: column;
}

dialog:not([open]) {
  display: none !important;
}
dialog::backdrop,
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0,0,0,0.5);
}
