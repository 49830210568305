
// Layout
$layout-maxwidth: 1200px;
$modal-maxwidth: 960px;
$modal-minwidth: 520px;
$article-maxwidth: 788px;
$gutter: 1.5rem;
$height-header: 96px;
$footer_height: 170px;

$mobile-width: 768px;
$alpha-width: 880px;
$beta-width: 1020px;
$charlie-width: 1200px;
$delta-width: 1380px;

// Fonts
$ff_body: "Circular", sans-serif;


// Typographic measurements
$fs_base: 18px; // This is 1rem;

$fs_xsmall: 12px;
$fs_small: 16px;
$fs_medium_small: $fs_base;
$fs_medium: 21px;
$fs_medium_large: 24px;
$fs_large: 30px;
$fs_xlarge: 42px;
$fs_xxlarge: 58px; // This problably won't be used in this project
$fs_xxxlarge: 72px;

$lh_base: 1.5;
$lh_header: 1.2;

$fw_light: 300;
$fw_normal: 400;
$fw_medium: 500;
$fw_semibold: 600;
$fw_bold: 700;
$fw_black: 900;

// Color palette
$c_white: #FFF;
$c_black: #000;
$c_yellow: #fffc4d;
$c_banana-yellow: #fffc4d;
$c_light-grass-green: #8ffe58;
$c_dark-sage-green: #64744B;
$c_coral: #ff634f;
// grays
$c_charcoal-grey: #2e3a40;
$c_light-grey-blue: #adc0c9;
$c_light-blue-grey: #c9cecf;
$c_oslo-gray: #969c9f;
$c_slate-grey: #5c6d74;
$c_steel: #7a8489;
$c_pale-grey: #f6f8f9;
$c_pale-grey2: #ecedee;
$c_gunmetal: #4e5c62;
$c_cool-grey: #969c9f;

$c-pie-1: #643335;
$c-pie-2: #835c5d;
$c-pie-3: #a28586;
$c-pie-4: #c1adae;
$c-pie-5: #ff634f;
$c-pie-6: #ff8272;
$c-pie-7: #ffa195;
$c-pie-8: #ffc1b9;

// TODO: change names when design has update theirs
$c-spinner-drop: rgba(255, 255, 255, 0.5);
$c_fyllnad-inaktiv-knapp: rgba(78, 92, 98, 0.3);
$c_coral-20: rgba(255, 99, 79, 0.2);
$c_yellow-20: rgba($c_yellow, 0.2);
// Functional colors
$c_action: $c_coral; // When should this be used? -LR
$c_warning: $c_coral;
$c_error: $c_coral;
$c_error--hover: $c_coral;
$c_error--focus: $c_coral;
$c_error--active: red;
$c_error--disabled: $c_light-grey-blue;

$c_accent: $c_yellow;
$c_accent--hover: #e6e200;
$c_accent--focus: #e6e200;
$c_accent--active: #ccc900;

// Element/component specific colors. In few cases component colors can be
// defined here but in gerenal they should be placed in the componnet scss.
// These would be fundamental elements, like button and link which are then
// used in other places
$c_body: $c_pale-grey;
$c_text: $c_charcoal-grey;
$c_body-text: $c_charcoal-grey;

$c_link: $c_action;
$c_link--hover: mix($c_link, black, 95%);
$c_link--focus: mix($c_link, black, 95%);
$c_link--active: mix($c_link, black, 90%);
$c-link--hover-blue:  #007bff;

// Are these to be used?

// $c_border: pink;
// $c_border--hover: pink;
// $c_border--focus: pink;
// $c_border--active: purple;

$c_border: $c_charcoal-grey;
$c_border--hover: $c_charcoal-grey;
$c_border--focus: $c_charcoal-grey;
$c_border--active: $c_charcoal-grey;

$c_button: $c_slate-grey;
$c_button--hover: $c_gunmetal;
$c_button--focus: $c_gunmetal;
$c_button--active: $c_charcoal-grey;
$c_button--disabled: $c_light-blue-grey;
$c_button_text: $c_banana-yellow;
$c_button_text--hover: $c_banana-yellow;
$c_button_text--focus: $c_banana-yellow;
$c_button_text--active: $c_banana-yellow;
$c_button_text--disabled: $c_cool-grey;

$c_button-2: $c_white;
$c_button-2--hover: $c_pale-grey;
$c_button-2--focus: $c_pale-grey;
$c_button-2--active: $c_light-grey-blue;
$c_button-2--disabled: $c_white;
$c_button-2_text: $c_body-text;
$c_button-2_text--hover: $c_body-text;
$c_button-2_text--focus: $c_body-text;
$c_button-2_text--active: $c_body-text;
$c_button-2_text--disabled: $c_cool-grey;

$c_button-link: transparent;
$c_button-link--hover: transparent;
$c_button-link--focus: transparent;
$c_button-link--active: transparent;
$c_button-link--disabled: transparent;
$c_button-link_text: $c_body-text;
$c_button-link_text--hover: $c_body-text;
$c_button-link_text--focus: $c_body-text;
$c_button-link_text--active: $c_black;
$c_button-link_text--disabled: $c_cool-grey;


// Borders
$border: 2px solid $c_border;
$border-radius: 4px;
$border-radius_rounded: 8px;
$border-radius_button: 0;

$time-quick: 300ms;
$time-expand: 400ms;
$animation-ease-default: ease-out;
$animation-speed-default: 0.2s;
