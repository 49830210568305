@import "src/styles/settings";
// developer.mozilla.org/en-US/docs/Web/HTML/Element/details
details {
  background-color: $c_white;
  padding: 1rem 1.66rem;

  &[open] > summary:after {
    transform: rotate(180deg);
  }

  & > summary {
    position: relative;
    border: none;
    font-size: $fs_medium_large;
    font-weight: $fw_bold;
    cursor: pointer;
    // Remove arrow
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    .details_summary_label {
      flex: 1
    }
    .details_summary_info {
      margin-right: 5rem;
      font-size: 80%;
    }
    // &::-webkit-details-marker {
    //   display: none;
    // }
    // &::selection {
    //   background-color: transparent
    // }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 24px;
      height: 100%;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" stroke="darkslategray" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8l8 9 9-9"/></svg>');
      background-repeat: no-repeat;
      background-position: center;

    }
  }
  & > section,
  & > [class$="content"] {
    padding-top: 2rem;
  }
}
