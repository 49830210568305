@import "src/styles/settings.scss";
@import "src/components/article-header/article-header";
@import "src/components/card/_card.scss";
@import "src/styles/_typography";


.contact {
 
  &__header {
    @include article-header();

      &__content {
        &__title {
          @include header($fs_xlarge);
          margin-bottom: 0.5rem;
      
          @media screen and (min-width: 535px) {
            font-size: $fs_xxlarge;
          }
      
          @media screen and (min-width: $mobile-width) {
            font-size: $fs_xxxlarge;
          }
        }
      }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    width: 100%;
    margin-bottom: 2rem;
    &:nth-child(odd) {
      margin-right: 0rem;
      @media screen and (min-width: $alpha-width) {
        margin-right: 2rem;
      }
    }
    @media screen and (min-width: $alpha-width) {
      width: calc(50% - 1rem);
    }

  }
  &__card {
    @include card();
    height: auto;
    font-size: $fs_small;
    // height: 219px;
    @media screen and (min-width: $alpha-width) {
      height: 100%;
    }
    &__container {
      display: flex;
      padding: 1.3rem;
      flex-direction: row-reverse;
      justify-content: space-between;
      @media screen and (min-width: $alpha-width) {
        flex-direction: row;
        justify-content: flex-start;
      }
    }
    &__image {
      width: 156px;
      height: 156px;
      object-fit: cover;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      @media screen and (min-width: $alpha-width) {
        margin-right: 1.3rem;
      }
    }
    &__content {
      padding: 0 1rem;
      max-width: calc(100% - 170px);
    }
    &__title {
      @include header($fontsize: $fs_medium, $fontweight: $fw_bold);
      margin-top: 0;
      margin-bottom: 0;
      line-height: $lh_base;
      @media screen and (min-width: $alpha-width) {
      }
    }
    &__subtitle {
      @include header($fontsize: $fs_small, $fontweight: $fw_semibold);
      line-height: $lh_header;
    }
    &__email {
      color: $c_coral;

      &__overflow-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0;
        margin-bottom: 0;
      }
      &:hover {
        color: $c-link--hover-blue;
      }
    }
    &__phone {
      opacity: 1;
      color: $c_charcoal-grey;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }
    a, p, p+p {
      margin-bottom: 0;
      line-height: 1.2;
    }
  }
}
