@import "src/styles/settings";
@import "src/styles/mixins/_container";
@import "src/components/logo/_logo.scss";

.footer {
  padding: 2rem 0 ;
  background-color: $c_slate-grey;
  color: $c_white;

  &__container {
    @include container;
    // padding-left: 6rem;
    // padding-right: 6rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    @media all and (min-width: $beta-width) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__contact {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media all and (min-width: $alpha-width) {
      flex-direction: row;
    }

    & > * {
      margin-right: 5rem;
      &:last-child {
        margin-right: 0;
      }
    }
    &__block {
      margin-bottom: 1rem;
      @media all and (min-width: $alpha-width) {
        margin-bottom: 0;
      }
    }
    &__header {
      margin-bottom: 5px;
      font-weight: $fw_bold;
      font-size: $fs_small;
      line-height: 1.43;
      @media all and (min-width: $mobile-width) {
        font-size: $fs_medium;
      }
    }
    p {
      font-size: $fs_small;
      margin: 0;
      color: $c_white;
      @media all and (min-width: $mobile-width) {
        font-size: $fs_medium;
      }
      a {
        color: inherit;
        opacity: 1;
        transition: opacity 0.2s;
      &:hover {
        opacity: 0.8;
      }
      }
    }
  }

  &-logo {
    @include logo();
    color: $c_white;
    margin-top: 2rem;
    @media all and (min-width: $beta-width) {
      margin-top: 0;
    }
  }
}
