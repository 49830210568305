@import "src/styles/settings";
@import "src/styles/_typography";

@mixin logo(
  $type:"light",
  $size:"medium",
) {

  display: flex;
  align-items: center;

  // on light background
  fill: $c_slate-grey;
  color: $c_charcoal-grey;
    // // on dark background
    // fill: $c_white;
    // color: $c_white;

  &__image{
    fill: inherit;
    height: 60px;
  }

  &__name {
    color: inherit;
    margin-left: 1em;
    margin-bottom: 0;
    font-size: $fs_medium;
  }
}
