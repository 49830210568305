@import "src/styles/elements/_button";
@import "src/styles/settings";

.month-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  background: $c_white;
  // @include button-2-factory();
  border: solid 2px $c_black;

  &:hover {
    cursor: pointer;
  }
  &[data-state-disabled='true']:hover {
    cursor: not-allowed;
  }
  &__label {
    font-size: $fs_small;
  }
  &__icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    stroke: $c_charcoal-grey;
  }

  &__calendar {
    font-family: $ff_body;
    font-size: $fs_base;
    font-weight: $fw_medium;
    color: $c_charcoal-grey;
    text-align: left;
    line-height: 1;
    padding: 1rem 5rem 1rem 1rem;
    width: 100%;
  }
}
