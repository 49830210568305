@import "src/styles/settings";
@import "src/components/article-header/article-header";
@import "src/components/card/_card.scss";
@import "src/styles/elements/_button";


.news-page {

  &__header {
    @include article-header();
    &__content {

      &__title {
        @include header($fs_xlarge);
        margin-bottom: 0;
    
        @media screen and (min-width: 535px) {
          font-size: $fs_xxlarge;
        }
    
        @media screen and (min-width: $mobile-width) {
          padding-right: 3rem;
          white-space: nowrap;
          font-size: $fs_xxxlarge;
        }
      }
    }
  }
  
  &__news {
    &-bar {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &-header {
      margin-bottom: 0;
      &__text {
        margin-bottom: 0;
        color: $c_slate-grey;
        font-size: $fs_medium;
      }
      &__link {
        color: $c_coral;
      }
    }
    
    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (min-width: $alpha-width) {
        flex-direction: row;
      }
    }
    &-item {
      width: 100%;
      margin-bottom: 2rem;
      &:nth-child(odd) {
        margin-right: 0;
        @media screen and (min-width: $alpha-width) {
          margin-right: 2rem;
        }
      }
      @media screen and (min-width: $alpha-width) {
        width: calc(50% - 1rem);
      }
    }
  }
  &__card {
    @include card();
    height: 371px;
    overflow: hidden;
    
    &__content {
      font-size: $fs_medium;
      &:after {
        content: '';
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $c_white 59%);
        bottom: 0;
        top: 14.5rem;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
    &__title {
      margin-top: 1rem;
      font-size: $fs_large;
    }
    &__supertitle {
      font-weight: normal;
      font-size: $fs_small;
      color: $c_charcoal-grey;
    }
     &__image + &__content {
       padding-top: 0.5rem;
    }
    &__bgimage {
      background-position: top;
      background-size: cover;
      padding-bottom: 0;
      height: 177px;
    }
    &__image {
      height: auto;
      width: 100%;
      max-height: 177px;
      object-fit: cover;
    }
  }
  &__load {
    &-more {
      display: flex;
      justify-content: center;
    }
    &-button {
      @include button-2-factory();
      font-size: $fs_medium;
    }
  }
}
