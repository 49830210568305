@import "src/styles/_page-article";
@import "src/styles/settings";
@import "src/styles/elements/_button";
@import "src/components/article-header/article-header";

.timeline {
  margin: 0 2rem 0 0;
  width: 100%;
  &__label {
    font-weight: $fw_bold;
    font-size: $fs_small;
    @media screen and (min-width: 520px) {
      font-size: $fs_base;
    }
  }
  &__slider-container {
    display: flex;
    margin: 20px 0;
    position: relative;
  }
  &__slider {
    width: 490px;
    margin-top: 8px;
    margin-left: 70px;
    &__labels {
      @include label($fs_xsmall);
      margin: 0;
      display: flex;
      justify-content: space-between;
      @media screen and (min-width: 520px) {
        font-size: $fs_small;
      }
      &__label {
        &:first-child {
          margin-left: -8px;
        }
        &:last-child {
          margin-right: -8px;
        }
      }
    }
    input[type='range'] {
      width: 100%;
      height: 24px;
      overflow: hidden;
      cursor: pointer;
    }
    input[type='range'],
    input[type='range']::-webkit-slider-runnable-track,
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
      height: 4px;
      background: $c_charcoal-grey;
      &:hover {
        background-color: $c_button--hover;
      }
    }
    input[type='range']::-webkit-slider-thumb {
      position: relative;
      height: 24px;
      width: 24px;
      margin-top: -10px;
      border-radius: 50%;
      border: 2px solid $c_white;
      background-color: $c_slate-grey;
      &:hover {
        background-color: $c_button--hover;
      }
    }
    input[type='range']::-webkit-slider-thumb::before {
      position: absolute;
      content: '';
      height: 4px; /* equal to height of runnable track */
      width: 500px; /* make this bigger than the widest range input element */
      left: -502px; /* this should be -2px - width */
      top: 8px; /* don't change this */
    }
  }
  &__icon-wrapper {
    position: absolute;
    @include button-factory;
    padding: 0;
    border-radius: 999em;
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 40px;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
  }
}