@import "src/styles/elements/_table.scss";

.projects {
  &__page {
    background: $c-pale-grey;
    &__search {
      padding-top: 2rem;
      &__header {
        justify-content: center;
        h3 {
          margin: 0;
        }
      }
    }
    .data-table {
      th {
        padding-left: 0rem;
        &:nth-child(1) {
          padding-left: 1rem;
          padding-right: 0rem;
          svg {
            color: $c-white;
            margin-bottom: -5px;
          }
        }
      }
      td {            
        &:first-child {
          font-weight: $fw_bold;
          padding-left: 1rem;
          padding-right: 1rem;
          text-align: center;
          .icon_cross {
            color: $c_light-blue-grey;
          }
        }
        &:nth-child(2) {
          width: 50%;
          padding-right: 1rem;
        }
        & > span:nth-child(2) {
            font-size: 0.9rem;
            color: $c-steel;
            display: block;
        }
        sub {
          font-size: $fs_small;  
          color: $c-slate-grey;  
        }
      }
    }
  }
}