@mixin card() {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: $c_white;

  &__content {
    padding: 1.5rem;
  }

  &__title {
    margin-top: 1.5rem;
  }

  &__container {
    width: 100%;
  }

  &__image {
    height: 0;
    width: 100%;
    object-fit: contain;
  }

  &__bgimage {
    height: 0;
    width: 100%;
    // background-image set inline on element
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 125%; // 3:4 aspect ratio as default. Override when needed.
  }

  &__header {
    font-size: 18px;
    font-weight: $fw_bold;
    margin-bottom: 0em;
    white-space: normal;
  }
}