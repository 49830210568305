@import "src/styles/settings";

@mixin definition-listing() {

  font-size: $fs_medium;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  
  &--grid-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  &--grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &--grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &--grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }    

  &__block {
    position: relative;
  }
  &__header {
    display: flex;
    align-items: center;
    font-weight: $fw_medium;
    background-color: $c_pale-grey;
    color: $c_slate-grey;
    stroke: $c_slate-grey;
    fill: $c_slate-grey;
    max-height: 52px;
    overflow: auto;
    cursor: pointer;
    &-highlight {
      color: $c_black;
    }
    & > * {
      padding: 0.5em 0.75em;
    }
    a {
      color: inherit;
      flex-grow: 1;
      &:hover {
        color: $c_black;
      }
    }
    &-icon {
      margin-left: auto;
      transition: rotateZ $time-quick;
      [data-active="true"] & {
        transform: rotateZ(45deg);
      }
      &:hover {
        cursor: pointer;
        stroke: $c_black;
        fill: $c_black;
      }
    }

  }
  &__details {
    position: absolute;
    width: 100%;
    z-index: 10;
    overflow: hidden;
    transition: max-height 0;
    max-height: 0;
    [data-active="true"] ~ & {
      transition: max-height $time-expand;
      max-height: 500px;
    }
  }
  &__detail {
    padding: 0.5em 0.75em;
    background-color: $c_pale-grey;
    color: $c_coral;
    stroke: $c_slate-grey;
    fill: $c_slate-grey;

    &:hover {
      color: $c-link--hover-blue;
      stroke: $c_black;
      fill: $c_black;
    }
    a {
      color: inherit;
    }
  }
}
