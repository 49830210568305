@import "src/styles/settings";
@import "src/styles/_typography";

.sweden-map {
  width: 100%;
  position: relative;
  max-width: 254px;

  svg {
    path {
      transition: fill 1s ease-out;
    }
  }
  &__region {
    &--active {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  &__tooltip {
    position: relative;
    z-index: 5;
    &__info {
      margin: 0 0.5rem;
      position: fixed;
      width: auto;
      padding: 1rem;
      background-color: $c_pale-grey;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
      font-size: $fs_xsmall;
      @media screen and (min-width: 550px) {
        font-size: $fs_small;
        max-width: none;
      }
      h6 {
        font-size: $fs_small;
        @media screen and (min-width: 550px) {
          font-size: $fs_base;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    &__arrow {
      width: 15px;
      height: 15px;
      background: $c_pale-grey;
      transform: rotate(
      45deg
      ) translateX(-50%);
      position: absolute;
      bottom: -10px;
      left: 25%;
      @media screen and (min-width: $mobile-width) {
        left: 75%;
      }
    }
  }
  &__legend {
    position: absolute;
    bottom: 1rem;
    right: 0;
    height: 10rem;
    padding: 0 1rem;
    @media screen and (min-width: $mobile-width) {
      height: 14rem;
    }
    &__bar {
      height: 100%;
      width: 0.8rem;
      position: absolute;
      top: 0;
      right: 0;
      @media screen and (min-width: $mobile-width) {
        width: 1.1rem;
      }
    }
    &__value {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 0.5rem;
      p {
        color: $c_steel;
        font-size: $fs_xsmall;
        margin-bottom: 0;
      }
    }
  }
}