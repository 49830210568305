@import "src/styles/settings";
@import "src/styles/mixins/_container";

.tab-bar {
  @include container();
  padding-top: 3rem;
  &__label {
      margin-right: 3.33rem;
  }
  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;

  }
  &__text {
    font-size: $fs_medium;
    font-weight: 500;
    line-height: 2.75rem;
    padding-bottom: 0.4rem;
    border-bottom: 4px solid transparent;

    &:hover {
      cursor: pointer;
    }
    :checked~& {
      border-bottom-color: $c_charcoal-grey;
    }
  }
}
