@import "src/styles/settings";

@mixin container(
 $max-width: $layout-maxwidth,
 $gutter-rem: $gutter,
 $base-fontsize: $fs_base
) {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media all and (min-width: $alpha-width) {
    padding-left: $gutter-rem;
    padding-right: $gutter-rem;
  }

  // @media all and (min-width: #{$max-width + $base-fontsize * strip-unit($gutter-rem) }) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}
