@import "src/styles/settings.scss";
@import "src/styles/mixins/_container";


@mixin article() {
  @include container($max-width: $article-maxwidth);
  padding-left: 0;
  padding-right: 0;
  
  @media screen and (min-width: $alpha-width) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__content {
    padding: 2rem 0;
  }
}

.article {
  @include article();
}