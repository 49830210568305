
.logout-page {
  max-width: 26rem;
  margin: 0 auto;
  text-align: center;

  &__intro {
    margin-bottom: 2em;
  }
}
