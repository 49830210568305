@import "src/styles/settings";
@import "src/styles/_typography";
@import "src/styles/mixins/_container";

.graph {
  @include container;
  background: $c_white;
  margin: 2rem auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
  
  @media all and (min-width: $charlie-width) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &__title {
    @include subtitle();
  }
  
  &__wrapper {
    width: 100%;
    height: 400px;
    position: relative;
    margin: 2rem auto;

    @media screen and (min-width: $mobile-width) {
      margin: 3rem auto;
    }
    &__yLabel {
      font-size: $fs_xsmall;
      @media screen and (min-width: 550px) {
        font-size: $fs_small;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
    font-size: $fs_small;
    @media all and (min-width: 550px) {
      flex-direction: row;
      margin-top: 2rem;
    }
    @media screen and (min-width: $mobile-width) {
      font-size: $fs_base;
    }
    p {
      margin: 0;
    }
    strong {
      margin-bottom: 0;
      font-weight: $fw_bold;
      font-size: $fs_small;
      @media screen and (min-width: $mobile-width) {
        font-size: $fs_base;
      }
    }
    .button-link-red {
      padding: 0;
      // align-self: flex-start;
      margin-bottom: 0.5rem;
      font-size: $fs_small;
      @media all and (min-width: 535px) {
        margin-bottom: 0;
      }
      @media screen and (min-width: $mobile-width) {
        font-size: $fs_base;
      }
    }
  }
}