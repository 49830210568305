@import "src/styles/settings.scss";
@import "src/components/article-header/article-header";
@import "src/styles/_typography";
@import "src/styles/_page-article";

.sanity-page-volumes {
  @extend .page-article;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  @media screen and (min-width: $alpha-width) {
    flex-direction: row;
  }
  .article {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  &__wrapper {
    width: 100%;

    @media screen and (min-width: $alpha-width) {
      width: 66.6%;
    }
  }
  &__graph {
    @extend .page-article;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $alpha-width) {
      padding-top: 4.5rem;
      width: 33.3%;
      justify-content: flex-end;
    }
  }
  .page-article {
    padding-bottom: 1rem;
  }
}