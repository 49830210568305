@import "src/styles/elements/_button";
.input-array {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;


  &__outer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

  }
  &__inner {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    &__clear {
      position: absolute;
      cursor: pointer;
      right: 0px;
    }
    &__delete {
      position: absolute;
      cursor: pointer;
      right: 20px;
    }
    &__op {
      cursor: pointer;
      right: 10px;
      width: 20px;
      visibility: hidden;
    }
    &:hover &__op {
      visibility: visible;
    }
    &__input {
      // @include button-2-factory();
      @include button-2-factory();
      text-align: left;
      border: solid 2px $c_black;
      padding: 1rem;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      /* Firefox */
      &[type=inline] {
        -moz-appearance: textfield;
      }
      
    }
  }
}
