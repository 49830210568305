@import "src/styles/settings";
@import "src/styles/elements/_table.scss";

.data-list {

  @include table("large", $c_pale-grey);
  
  &__header {
    background: $c_gunmetal;
    color: $c_white;
    font-weight: $fw_bold;
    padding: 1rem;
  }

  &__body > * {
    background: $c_pale-grey;
    padding: 1rem;
  }
  &__body > *:nth-child(even) {
    background: $c_white;
    padding: 1rem;
  }
  &__footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    gap: 1rem;
  }

}