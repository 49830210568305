@import "src/styles/settings";

.main {
  position: relative;
  min-height: calc(100vh - #{$height-header} - #{$footer_height});
  display: flex;
  flex-direction: column;
  body & {
    min-width: $layout-maxwidth; // The layout will show scrollbars lower than this width
  }
}
