@import "src/styles/elements/_table.scss";

.project {
  &__overview {
    &__page {
      background: $c-pale-grey;
      &__table {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}
