@import "src/styles/mixins/_container";
@import "src/components/definition-listing/_definition-listing.scss";
@import "src/components/article-header/article-header";


.county-listing {
  &__header {
    &-icon {
      position: absolute;
      right: 0;
    }
  }
  &__block {
    break-inside: avoid-column;
    backface-visibility: hidden;
  }
  &__wrapper {
    background-color: $c_white;
    
    &__header {
      @include article-header();
      &-icon {
        position: absolute;
        right: 0;
      }
      &-highlight {
        font-weight: $fw_black;
        color: $c_slate-grey;
      }
    }
  }

  &__container {
    @include container;
    padding-top: 1rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-between;
  }

  & {
    @include definition-listing();
    // columns: 4 270px;


    width: 100%;
    // flex-grow: 1;
  }
  // &__map {
  //   flex-basis: 25%;
  //   padding: 0 3rem;
    
  //   &__graph {
  //     width: 100%;
  //     object-fit: contain;
  //     height: auto;
  //   }
  // }
}
