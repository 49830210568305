@import "src/styles/settings";

.snackbar {
  &--show {
    height: 2em;
    opacity: 1;
    transition: all 0.1s ease;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $c_charcoal-grey;
  padding: 0.5em;
  &__inner {
    padding: 1rem ;
    display: grid;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: 100px 1000px 100px;
    column-gap: 20px;
    color: $c_banana-yellow;
    &__icon {
      color: $c_banana-yellow;
      svg {
        transform: scale(1.5);
        margin-top: 2px;
      }
      text-align: right;
    }

    &__label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $fs_medium;
      font-weight: bold;
      color: $c_banana-yellow;
    }
    &__message {
      font-size: 1.5em;
      font-size: $fs_medium;
      flex: 1;
      text-align: center;

      & details {
        background-color: transparent;
        padding: 0;
      
        summary {
          text-align: center;
          font-size: inherit;
        }
        text-align: left;
        background: transparent;
        cursor: pointer;
      }

    }
    &__close {
      font-size: 1.5em;
      font-size: $fs_medium;
      color: $c_banana-yellow;
      justify-self: flex-end;
      cursor: pointer;
      svg {
        transform: scale(1.5);
        margin-top: 2px;
      }

    }
  }
  &__close {
    font-size: 1.5em;
    cursor: pointer;
  }
}