@import "src/styles/settings";
@import "src/components/article-header/article-header";
@import "src/components/card/_card.scss";

.start-page {

  &__wrapper__header {
    @include article-header();
    padding-bottom: 0;
    @media screen and (min-width: $alpha-width) {
      padding-bottom: 2rem;
    }
  }
  &__wrapper__header-highlight {
    font-weight: $fw_black;
    color: $c_slate-grey;
  }
  &__header__super-title {
    margin-bottom: 1rem;
    
    &__img {
      fill: $c_slate-grey;
      height: 25px;
      width: 214px;
    }
  }
}
