@import "src/styles/settings";
@import "src/styles/_typography";

.form-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_label {
    display: block;
    font-size: $fs_small;
  }
  &_text-field {
    width: 100%;
  }

  &_field {
    &-container {
      position: relative;
      // flex-basis: calc(50% - 0.5rem); // TODO: find a way to avoid guessed widths
      // max-width: calc(50% - 0.5rem);
    }
    &-subcontainer {
      display: flex;
      justify-content: space-between;

      & > * {
        min-width: 0; // Prevents from overflow. See https://stackoverflow.com/a/36247448/7197027
        flex-grow: 1;
        &:last-child {
          margin-left: 1.33rem;
        }
      }
    }

    &-title {
      @extend .h5;
      flex-basis: 100%;
      flex-grow: 1;
    }
  }
  &_submit-bar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & > * {
      margin-left: 1rem;
    }
  }

}
