@import "src/styles/settings.scss";

@mixin header( $fontsize: $fs-base, $fontweight: $fw_black) {
  font-size: $fontsize;
  font-weight: $fontweight;
  line-height: $lh_header;
  margin-bottom: 0.5em;
}

h1, .h1 {
  @include header($fs_xxlarge);
}
h2, .h2 {
  @include header($fs_large);
  @media screen and (min-width: $mobile-width) {
    font-size: $fs_xlarge;
  }
}
h3, .h3 {
  @include header($fs_medium);
  @media screen and (min-width: $alpha-width) {
    font-size: $fs_large;
  }
}
h4, .h4 {
  @include header($fs_medium_large);
}
h5, .h5 {
  @include header($fs_medium);
}
h6, .h6 {
  @include header($fs_base);
}

p {
  margin-bottom: 1em;
}

@mixin label( $fontsize: $fs-base) {
  font-size: $fontsize;
  font-weight: $fw_medium;
  line-height: $lh_base;
  margin-bottom: 0.5em;
}

label, .label {
  @include label();
}

@mixin subtitle() {
  font-size: 27px;
  font-weight: $fw_normal;
  color: $c_slate-grey;
}

ul {
  list-style-type: none;
}
// TODO: add title declaration



// %title-L {
//   font-size: $fs_large;
//   font-weight: $fw_bold;
// }
//
// %title-M {
//   font-size: $fs_medium;
//   font-weight: $fw_black;
// }
//
// %text-L {
//   font-size: $fs_medium;
//   font-weight: normal;
// }
//
// %text-M {
//   font-size: $fs_base;
//   font-weight: $fw_bold;
// }
//
// %text-S {
//   font-size: $fs_small;
//   font-weight: normal;
// }
