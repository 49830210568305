@import "src/styles/settings";

/**
 * Base button mixin with variant factories
 */
@mixin button-factory(
  $size: "MS",
  $color: $c_button_text,
  $color-hover: $c_button_text--hover,
  $color-focus: $c_button_text--focus,
  $color-active: $c_button_text--active,
  $color-disabled: $c_button_text--disabled,

  $background-color: $c_button,
  $background-color-hover: $c_button--hover,
  $background-color-focus: $c_button--focus,
  $background-color-active: $c_button--active,
  $background-color-disabled: $c_button--disabled,

  $border: "1px solid" $background-color,
  $border-color-hover: $background-color-hover,
  $border-color-focus: $background-color-focus,
  $border-color-active: $background-color-active,
  $border-color-disabled: $background-color-disabled,

  $icon-color: $color,
  $icon-color-hover: $color-hover,
  $icon-color-focus: $color-focus,
  $icon-color-active: $color-active,
  $icon-color-disabled: $color-disabled,

  $border-radius: $border-radius_button,
  $padding: "1em 1.66em", // Relative padding to font size.
  $font-size: $fs_medium,
  $font-weight: $fw_medium,
  $icon-position: "none"
) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: #{$padding};
  color: $color;
  background-color: $background-color;
  border: #{$border};
  border-radius: $border-radius;
  font-family: $ff_body;
  font-weight: $font-weight;
  white-space: nowrap;
  text-align: center;
  line-height: $lh_header;

  font-size: $fs_base;

  // An other way of changing size is placing a data attibute on the DOM element.
  &[data-size="S"] {
    font-size: $fs_small;
  }
  &[data-size="MS"] {
    font-size: $fs_medium_small;
  }
  &[data-size="M"] {
    font-size: $fs_medium;
  }
  &[data-size="ML"] {
    font-size: $fs_medium_large;
  }
  &[data-size="L"] {
    font-size: $fs_large;
  }
  &[data-size="XL"] {
    font-size: $fs_xlarge;
  }
  &[data-size="XXL"] {
    font-size: $fs_xxlarge;
  }

  // &::selection {
  //   background-color: transparent
  // }

  &:hover {
    cursor: pointer;
    color: $color-hover;
    border-color: $border-color-hover;
    // background-color: $background-color-hover;

    svg {
      fill: $icon-color-hover;
      stroke: $icon-color-hover;
    }
  }
  &:focus {
    color: $color-focus;
    border-color: $border-color-focus;
    // background-color: $background-color-focus;

    svg {
      fill: $icon-color-focus;
      stroke: $icon-color-focus;
    }
  }
  &:active,
  &[data-active="true"] {
    color: $color-active;
    border-color: $border-color-active;
    background-color: $background-color-active;

    svg {
      fill: $icon-color-active;
      stroke: $icon-color-active;
    }
  }

  &[disabled],
  &[data-disabled="true"],
  &[aria-disabled="true"] {
    cursor: default;
    color: $color-disabled;
    border-color: $border-color-disabled;
    background-color: $background-color-disabled;
    svg {
      fill: $icon-color-disabled;
      stroke: $icon-color-disabled;
    }
  }

  svg {
    fill: $icon-color;
    stroke: $icon-color;
    vertical-align: baseline;
  }

  // svg:not([class="icon-right"]),
  // DART SASS TODO
    // &__icon-left,
    // &__icon[data-align="left"] {
    //     margin-right: 0.5em;
    //     margin-left: 0;
    // }

    // &__icon, // default orientation
    // &__icon-right
    // &__icon[data-align="right"] {
    //   margin-left: 0.5em;
    //   margin-right: 0;
    // }
}

@mixin setup-button($ns: "button") {
  .#{$ns} {
    @include button-factory();
  }
}
@include setup-button();

@mixin button() {
  @include button-factory();
}

/**
 * Secondary button mixin with variant factories
 */
 // TODO: Feed values directly into
@mixin button-2-factory(
  $size: "MS",
  $color: $c_button-2_text,
  $color-hover: $c_button-2_text--hover,
  $color-focus: $c_button-2_text--focus,
  $color-active: $c_button-2_text--active,
  $color-disabled: $c_button-2_text--disabled,

  $background-color: $c_button-2,
  $background-color-hover: $c_button-2--hover,
  $background-color-focus: $c_button-2--focus,
  $background-color-active: $c_button-2--active,
  $background-color-disabled: $c_button-2--disabled,

  $border: "2px solid" $color,
  $border-color-hover: $color-hover,
  $border-color-focus: $color-focus,
  $border-color-active: $color-active,
  $border-color-disabled: $color-disabled,

  $icon-color: $color,
  $icon-color-hover: $color-hover,
  $icon-color-focus: $color-focus,
  $icon-color-active: $color-active,
  $icon-color-disabled: $color-disabled,

  $border-radius: $border-radius_button,
  $padding: "1em 1.66em",
  $font-size: $fs_medium,
  $font-weight: $fw_medium,
  $icon-position: "none"
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,

    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,

    $border ,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,

    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    )

}

@mixin setup-button-2($ns: "button-2") {
  .#{$ns} {
    @include button-2-factory();
  }
}
@include setup-button-2();
// TODO: figure out how to pass over arguments to the factory mixinr
@mixin button-2() {
  @include button-2-factory();
}

/**
 * Secondary button mixin with variant factories
 */
@mixin button-link-factory(
  $size: "MS",
  $color: $c_button-link_text,
  $color-hover: $c_button-link_text--hover,
  $color-focus: $c_button-link_text--focus,
  $color-active: $c_button-link_text--active,
  $color-disabled: $c_button-link_text--disabled,

  $background-color: $c_button-link,
  $background-color-hover: $c_button-link--hover,
  $background-color-focus: $c_button-link--focus,
  $background-color-active: $c_button-link--active,
  $background-color-disabled: $c_button-link--disabled,

  $border: none,
  $border-color-hover: transparent,
  $border-color-focus: transparent,
  $border-color-active: transparent,
  $border-color-disabled: transparent,

  $border-radius: $border-radius_button,
  $padding: "0.5em 0",
  $font-size: $fs_medium,
  $font-weight: $fw_medium,
  $icon-position: "none"
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,

    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,

    $border ,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,

    $border-radius: $border-radius_button,
    $padding: "0.5em 0",
    )

}

@mixin setup-button-link(
  $ns: "button-link",
  $size:"MS",
  $args...
  ) {
  .#{$ns} {
    @include button-link-factory($size, $args...);
  }
}
@include setup-button-link();


@mixin setup-button-link-error(
  $ns: "button-link-red",
  $size:"MS",
  $color: $c_error,
  $color-hover: $c_error--hover,
  $color-focus: $c_error--focus,
  $color-active: $c_error--active,
  $color-disabled: $c_error--disabled,
  $args...
  ) {

  .#{$ns} {
    @include button-link-factory(
      $size,
      $color,
      $color-hover,
      $color-focus,
      $color-active,
      $color-disabled,
      $args...
    );
  }
}

@include setup-button-link-error();
