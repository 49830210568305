@import "src/styles/settings";
@import "src/styles/mixins/_container";
@import "src/styles/_typography.scss";
@import "src/styles/elements/_button";
@import "src/components/selector/_selector.scss";


.location-selector {
  background-color: $c_white;
  padding-top: 2rem;
  width: 100%;
  @media screen and (min-width: $alpha-width) {
    padding-top: 3rem;
  }

  &__container {
    @include container;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $beta-width) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__header {
    @include header($fs_xlarge);
    width: auto;
    margin-bottom: 0;
    
    @media screen and (min-width: 535px) {
      font-size: $fs_xxlarge;
    }
    
    @media screen and (min-width: $mobile-width) {
      padding-right: 3rem;
      white-space: nowrap;
      font-size: $fs_xxxlarge;
    }
  }

  &__selector {
    @include selector();
    padding-top: 2rem;
    width: 100%;
    min-width: 15rem;
    max-width: 30rem;

    @media screen and (min-width: $beta-width) {
      padding-top: 0;
    }
    
    &__button {
      @include button-factory(
        $color: $c_white, 
        $color-hover: $c_white, 
        $color-active: $c_white, 
        $color-focus: $c_white);
        justify-content: flex-start;
        width: 100%;
        max-width: 30rem;
        position: relative;
        
      &-text {
        color: $c_light-grey-blue;
        padding-right: 0.5rem;
      }
      &:focus, &:hover, &:visited {
        svg {
          stroke: $c_banana-yellow;
          fill: $c_banana-yellow;
        }

      }
      svg {
        stroke: $c_banana-yellow;
        fill: $c_banana-yellow;
      }
    }
    &__list {
      max-width: 30rem;
    }
    &__item {
      display: flex;
      flex-direction: column;

      &:active,
      &[data-selected='true'] {
      color: $c_charcoal-grey;
    }
    }
    &__list {
      & &__subItem {
        padding-left: 1rem;
        &:hover {
          color: $c_black;
        }
        &:last-child {
          padding-bottom: 0;
        }
        &:active {
          color: $c_action;
        }
      }
    }
  }
  .tab-bar {
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 560px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;    
    }
    &__label {
      margin-right: 1.5rem;
      @media screen and (min-width: 680px) {
        margin-right: 3.33rem;    
      }
    }
  }
}
