@import "src/styles/settings";
@import "src/styles/elements/_table.scss";


.data-table--isAdmin .rated_icon--MANUAL::after {
  content: ' ';
  display: block;
  width: 10px;
  height: 10px;
  background: #dc241e;
  border-radius: 10px;
  position: absolute;
  top: -0px;
  right: -0px;
}

.data-table--isAdmin .rated_icon--isManual::before {
  content: ' ';
  display: block;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 10px;
  position: absolute;
  top: -0px;
  left: -0px;
}


.data-table {

    &[data-nowrap] td {
      white-space: nowrap;
    }
    @include table("large", $c_pale-grey);
    width: 100%;
    &__clickable {
      cursor: pointer;
    }

    &__column {
      margin-left: 0.5rem;
      white-space: nowrap;
      &--sortable {
        cursor: pointer;
      }
      &::after {
        content: ' ';
        font-size: 13px;
        display: inline-block;
        width: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      &--asc::after {
        content: '▲';
        font-size: 13px;
        display: inline-block;
        margin-left: 0.5rem;
      }
      &--desc::after {
        content: '▼';
        font-size: 13px;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
    tfoot td{
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      gap: 1rem;
    }
    tbody {
      tr.data-table__lineAt {
        border-top: solid 1px black;
      }
      td {
        vertical-align: middle;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-right: 2rem;
        height: 3.5rem;
        menu {
          margin-top: -0.5rem;
          margin-bottom: -0.5rem;
          span {
            border-color: transparent;
            background-color: transparent;
            &:hover {
            }
          }
        }
        &[data-changed=false] {
          opacity: 0.2;
        }
      }

    }
    &_edit-icon {
      &:hover {
        cursor: pointer;
      }
    }
    &_arrow-icon {
      display: flex;
      stroke: $c_coral;
      &:hover {
        cursor: pointer;
      }
    }
  }