@import "src/styles/elements/_table.scss";
@import "src/styles/settings.scss";
@import "src/styles/mixins/_container";
@import "src/styles/_typography";
@import "src/styles/_page-grid";


.report-tab-bar {
  &__wrapper {
    background: $c_white;
  }
  &__container {
    @include container();
    position: relative;
    .tab-bar {
      position: relative;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
      
      &__form {
        overflow-x: scroll;
        white-space: nowrap;
        @media screen and (min-width: $mobile-width) {
          display: flex;
          align-items: center;        
        }
        &:after {
          content: '';
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #FFF 73%);
          bottom: 0px;
          top: 0px;
          right: 0;
          position: absolute;
          width: 10%;
          @media screen and (min-width: $mobile-width) {
            background-image: none;
          }
        }
      }
      &__label {
        margin-right: 1.5rem;
        margin-bottom: 0;
        @media screen and (min-width: 535px) {
          margin-right: 3.33rem;
        }
        
      }
      &__text {
        font-size: $fs_medium_small;
        @media screen and (min-width: 535px) {
          font-size: $fs_medium;
        }
      }
    }
  }
  &__download-link {
    display: none;
    
    .button-link-red {
      font-size: $fs_medium;  
    }
    @media screen and (min-width: $mobile-width) {
      display: block;
    }
  }
}