@import "src/styles/settings.scss";

.rated_icon {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 80%;
    position: relative;

    &--rated {
        background-color: #40a436;
        color: #fff;
    }

    &--notrated {
        background-color: #afafaf;
    }

   
}

.icon {
    &__arrow-right {
        display: flex;
        stroke: $c_coral;

        &:hover {
            cursor: pointer;
        }
    }

    &__cross {}

    &__check {
        color: $c_dark-sage-green;
    }

    &__info {
        color: inherit;
    }

    &__error {
        color: inherit;
    }

    &__wifi {
        color: inherit;
    }
}