.login-page {
  max-width: 26rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  &__intro {
    margin-top: 2em;
  }
}
