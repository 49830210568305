@import "src/styles/settings";
@import "src/styles/mixins/_container";

.page {
  min-height: 100vh;
  background: $c-white;
  &__header {
    background: $c-white;
    padding-top: 1rem;
    &__content {
      @include container;
    }
  }

  &__submenu {
    padding-top: 2rem;
    background: $c_pale-grey;
  }
  &__grid {
    &__content {
      @include container;
      display: grid;
      grid-row-gap: 30px;
      grid-column-gap: 26px;
      &--columns2 {
        grid-template-columns: repeat(2, 1fr);
      }
      &--columns3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &--columns4 {
        grid-template-columns: repeat(4, 1fr);
      }
      &--columns5 {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    &__header {
      @include container;
      display: flex;
      margin-bottom: 0.5rem;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      & > :first-child {
        flex: 1;
      }
    }
  }

  &__section {
    // @include container($max-width: $layout-maxwidth);
    &__content {
      @include container;
      display: flex;
    }
    &__header {
      @include container;
      display: flex;
      margin-bottom: 0.5rem;

      flex-direction: row;
      & > :first-child {
        flex: 1;
      }
    }
  }

  &__block {
    &--elevated {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3rem;
      background: $c-light-grey-blue;
      & > button {
        flex: 1;
      }
      & > button + button {
        margin-left: 2rem;
      }
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    label {
      font-size: $fs_small;
      color: $c-charcoal-grey;
    }
    &--span1 {
      grid-column: span 1;          
    }
    &--span2 {      
      grid-column: span 2;    
    }
    &--span3 {
      grid-column: span 3;          
    }
    &--span4 {
      grid-column: span 4;          
    }
    &--start1 {
      grid-column: 1;          
    }
    &--start2 {      
      grid-column: 2;    
    }
    &--start3 {
      grid-column: 3;          
    }
    &--start4 {
      grid-column: 4;          
    }
  }
}
