@import "src/styles/elements/_table.scss";
@import "src/styles/settings";
@import "src/styles/mixins/_container";
@import "src/styles/_typography";
@import "src/styles/_page-grid";

.region-data {
  &__section {
    padding-top: 1rem;
    padding-bottom: 1rem;  

    @media screen and (min-width: $mobile-width) {
      padding-top: 2rem;
      padding-bottom: 2rem;  
    }

    &--white {
      background: $c-white;
    }
    &--gray {
      background: $c-pale-grey;
    }
    &--white + &--gray {
      padding-top: 4rem;
    }
    &:last-child {
        padding-bottom: 4rem;
    }
    &--gray + &--gray {
      padding-top: 2rem;
    }
    &--white {
      padding-bottom: 4rem;
    }
    &__wrapper {
      @include container;
      display: flex;
      background-color: $c_white;
      padding-top: 2rem;
      padding-bottom: 2rem;

      @media all and (min-width: $charlie-width) { // Todo: make responsive
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    .prop-table__header {
      @include subtitle;
      height: auto;
    }
    .page-grid {
      width: 100%;
      .page-grid_2 {
        grid-column: span 4;
        padding-bottom: 1rem;

        .prop-table__header {
          padding-bottom: 2rem;
        }
      }
      @media all and (min-width: $mobile-width) {
        .page-grid_2 {
          grid-column: span 2;
          &:first-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .page-grid7 {
      width: 100%;
      .page-grid_3,
      .page-grid_4 {
        grid-column: span 7;
      }
      @media screen and (min-width: $beta-width) {
        .page-grid_3 {
          grid-column: span 3;
        }
        .page-grid_4 {
          grid-column: span 4;
        }
      }
    }
    &__header {
      @include container;
      display: flex;
      flex-direction: row;
      h2 {
        font-size: $fs_large;
        @media all and (min-width: 535px) {
          font-size: $fs_xlarge;
        }
      }

      & > :first-child {
        flex: 1;
      }
    }
  }
  &__description {
    padding-left: 0;
    .page-grid &{
      padding-bottom: 2rem;
    }
  }
  &__table {
    .prop-table__row {
      background: $c_white;
      padding-left: 0;
      padding-right: 0;
      &:nth-child(odd) {
        background: $c_pale-grey;
      }

      &__value {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        width: 100%;
        padding: 1rem 0;

        .value {
          white-space: nowrap;
          align-self: center;
          @media all and (min-width: $alpha-width) {
            align-self: flex-end;
          }
        }
        &-description {
          flex-direction: column;
          align-items: flex-start;
          .value {
            align-self: flex-end;
            padding-top: 0.5rem;
            @media all and (min-width: $alpha-width) {
              align-self: center;
              padding-top: 0;
            }
          }
        }
        p, h5 {
          margin-bottom: 0;
          padding: 0 1rem;
        }
        @media screen and (min-width: $alpha-width) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  &__link {
    color: $c_coral;
    text-align: center;
    margin-top: 1rem;
    font-size: $fs_medium;
    overflow-anchor: none;
  }
}