@import "src/styles/elements/_table.scss";

.project {
  &__settings {
    &__page {
      padding-bottom: 2rem;
      background: $c_pale-grey2;

      & .tab-bar {
        padding-top: 0;
      }
      & .data-table {
        margin-top: 1em;
      }
      &__table {
        background: $c_pale-grey2;
        padding-top: 4rem;
      }
      &__submenu {
        padding-top: 2rem;
        background: $c_pale-grey;
      }
    }
  }
}
